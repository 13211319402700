import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "@emotion/styled";

// import Categories from "../components/Listing/Categories";
import website from "../../config/website";
import SEO from "../components/seo";
import Layout from "../components/Layout";
import { Header } from "../components/collections/Header";
import { LogoPanel } from "../panels/logoPanel";
import { Footer } from "../components/collections/Footer";
import { Builder } from "../components/collections/Builder";

const Post = ({ data: { wordpressPost }, location }) => {
  const post = wordpressPost;

  const header = {
    textColor: post.acf.header_text_color,
    isParallax: post.acf.header_parallax,
    image: {
      ...post.acf.header_image_bg,
    },
    backgroundType: "image",
    title: post.title,
  };

  if (
    post.acf.header_image_bg &&
    post.acf.header_image_bg.localFile &&
    post.acf.header_image_bg.localFile.childImageSharp
  ) {
    header.image.src =
      post.acf.header_image_bg.localFile.childImageSharp.original.src;
  }

  const { content_builder_post } = post.acf;

  return (
    <Layout customSEO>
      {/* <SEO
        title={`${post.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={post.excerpt}
        article
      /> */}
      <Header {...header} />
      <Builder content={content_builder_post} />
      <LogoPanel />
      <Footer />
    </Layout>
  );
};

export default Post;

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    wordpressPost(slug: { eq: $uid }) {
      featured_media {
        source_url
        alt_text
        title
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      excerpt
      title
      status
      slug
      link
      guid
      sticky
      template
      content
      author {
        name
        url
        slug
        description
      }
      acf {
        header_parallax
        header_image_bg {
          alt_text
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        header_text_colour

        content_builder_post {
          ... on WordPressAcf_grid_content {
            heading
            image {
              alt_text
              source_url
              title
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            text
            text_width
            layout
          }
          ... on WordPressAcf_text_block {
            id
            text
            heading
            text_size
          }
        }
      }
      categories {
        link
        name
        slug
        count
        path
      }
    }
  }
`;
